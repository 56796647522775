/* eslint-disable max-classes-per-file */

import { debounce } from 'lodash';

import { makeNamespacedLog } from '@sb/log';
import { wait } from '@sb/utilities';
import {
  RGB_STREAM_MIMETYPE,
  RGB_STREAM_URL,
} from '@sbrc/services/camera-client';
import { globalCache } from '@sbrc/utils';

import { CameraStreamFramesTransformer } from './CameraStreamFramesTransformer';

const log = makeNamespacedLog('CameraStream');

// TODO(bpatmiller) We want the timeout to be conditional on the connection quality
const NO_DATA_RECEIVED_TIMEOUT = 3000;
const NO_LISTENERS_TIMEOUT = 3000;
const PAUSE_BEFORE_RETRY = 2000;
const NO_LISTENERS_MESSAGE = 'No listeners';

export const CAMERA_DISCONNECTED_MESSAGE = 'Camera is not connected';

class CameraStream {
  private isCreatingBitmap = false;

  public imageBitmap: ImageBitmap | null = null;

  public error: string | null = null;

  private abortController: AbortController | null = null;

  private abortWhenNoData = debounce(() => {
    this.abortController?.abort(new Error('No data received'));
  }, NO_DATA_RECEIVED_TIMEOUT);

  private abortWhenNoListeners = debounce(() => {
    this.abortController?.abort(new Error(NO_LISTENERS_MESSAGE));
  }, NO_LISTENERS_TIMEOUT);

  private async run() {
    this.abortController = new AbortController();
    const { signal } = this.abortController;

    try {
      this.abortWhenNoData();

      const response = await fetch(RGB_STREAM_URL, {
        signal,
        headers: { Accept: RGB_STREAM_MIMETYPE },
      });

      if (!response.ok) {
        switch (response.status) {
          case 429:
            throw new Error('Another session is connected');
          case 503:
            throw new Error(CAMERA_DISCONNECTED_MESSAGE);
          case 504:
            throw new Error('Camera service is not available');
          default:
            throw new Error(`${response.statusText} (${response.status})`);
        }
      }

      const { body } = response;

      if (!body) {
        throw new Error('Invalid stream response (no body)');
      }

      const transformer = new CameraStreamFramesTransformer();

      await transformer.start(body, async (blob) => {
        this.abortWhenNoData();

        if (this.isCreatingBitmap) {
          // drop this frame if busy decoding the previous one
          return;
        }

        if (blob.size === 0) {
          this.error = 'Empty frame received';

          return;
        }

        this.isCreatingBitmap = true;

        try {
          this.imageBitmap = await createImageBitmap(blob);
          this.error = null;
        } catch (e) {
          this.error = 'Error decoding frame';
          log.error('run', 'Error decoding frame', e);
        }

        this.isCreatingBitmap = false;
      });
    } catch (error) {
      if (signal.reason?.message === NO_LISTENERS_MESSAGE) {
        // not an error state, just ending the stream
        this.imageBitmap = null;
      } else if (signal.aborted && signal.reason instanceof Error) {
        this.error = signal.reason.message;
      } else if (error instanceof Error) {
        log.info('run', 'Run error', error);
        this.error = error.message;
      } else {
        log.error('run', 'Unknown run error', error);
        this.error = 'Unknown error';
      }
    } finally {
      await wait(PAUSE_BEFORE_RETRY);
      this.abortController = null;
    }
  }

  public touch() {
    if (!this.abortController) {
      this.run();
    }

    this.abortWhenNoListeners();
  }
}

export function getCameraStream() {
  const cachedGet = globalCache('cameraStream', () => {
    const cameraStream = new CameraStream();

    return () => {
      cameraStream.touch();

      return cameraStream;
    };
  });

  return cachedGet();
}
